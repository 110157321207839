
import { ref, onMounted, Ref } from "vue";
import { defineComponent } from "vue";
import Api from "../../services/Api";
import useAlert from "../../composables/Alert";
import Button from "@/components/Button.vue"
import { Modal } from "bootstrap";
import { uploadMediaFromClient, listaBlobs, deleteMediaFromClient } from "@/services/AzureService"
import ModalPlanilhaLubrificanteTransmissao from "@/views/planilhaLubrificanteTransmissao/components/ModalPlanilhaLubrificanteTransmissao.vue";
import ModalDownloadLubrificanteTransmissao from "./components/ModalDownloadLubrificanteTransmissao.vue";
import moment from "moment";

export default defineComponent({
  name: "UploadLubrificanteTransmissao",
  components: {
    Button,
    ModalPlanilhaLubrificanteTransmissao,
    ModalDownloadLubrificanteTransmissao,
  },

  setup() {

    const { showTimeAlert } = useAlert();

    const arquivo: Ref<any> = ref();
    const marca: Ref<string | null> = ref(null);
    const fileInput: Ref<any> = ref(null);
    const marcas: Ref<any> = ref([]);
    const historicoArquivos: Ref<any> = ref([]);
    const qtdArquivosSalvos: Ref<number> = ref(0)
    const file: Ref<any> = ref(null);
    const base64File: Ref<any> = ref();
    const loadingUpload: Ref<boolean> = ref(false);

    const caminhoDoArquivo = "gestao_tecnica/atualizacao_lubrificante_transmissao";

    const arquivoSelecionado: Ref<any> = ref({})
    const modalPlanilhaLubrificanteTransmissao: Ref<any> = ref()

    const modalDownloadPlanilhaFiltro: Ref<any> = ref()

    const openInput = () => fileInput.value.click();

    const uploadFile = async (event: any) => {
      const file = event.target.files[0]
      arquivo.value = file

      getBase64(file).then(base64String => {
        base64File.value = base64String;
      }).catch(error => {
        console.error('Error:', error);
      });
    };

    function getBase64(fileBlob) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();

        reader.onloadend = () => {
          resolve(reader.result);
        };

        reader.onerror = reject;
        reader.readAsDataURL(fileBlob);
      });
    }


    function dropHandler(e) {

      stopDefault(e)

      if (e.dataTransfer.items) {
        // Use DataTransferItemList interface to access the file(s)
        [...e.dataTransfer.items].forEach((item) => {
          // If dropped items aren't files, reject them
          if (item.kind === "file") {
            file.value = item.getAsFile();
          }
        });
      } else {
        // Use DataTransfer interface to access the file(s)
        [...e.dataTransfer.files].forEach((fileParam) => {
          file.value = fileParam;
        });
      }
      arquivo.value = file.value
    }



    function stopDefault(e) {
      // Prevent default behavior (Prevent file from being opened)
      e.stopPropagation();
      e.preventDefault();
    }


    // function formatBytes(bytes, decimals = 2) {
    //   if (bytes === 0) return '0 Bytes';
    //   const k = 1024;
    //   const dm = decimals < 0 ? 0 : decimals;
    //   const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
    //   const i = Math.floor(Math.log(bytes) / Math.log(k));
    //   return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
    // }

    function limparArquivo() {
      arquivo.value = null
    }

    async function enviarPlanilha() {
      loadingUpload.value = true
      const timeStamp = new Date().getTime();
      try {
        const content = arquivo.value;
        const caminhoAzure = `${caminhoDoArquivo}/${timeStamp}-${arquivo.value.name}`;

        const uploadBlobResponse = await uploadMediaFromClient(content, caminhoAzure)
        if (uploadBlobResponse._response.status === 201) {
          const res = await Api.post("atualizaLubrificanteTransmissao", { filePath: caminhoAzure });
          if (res && res.error) {
            //deleta da azure e manda toast
            console.error(res.message)
            loadingUpload.value = false

            deleteMediaFromClient(caminhoAzure)
            showTimeAlert('Erro ao salvar planilha', 'error')
          } else {
            showTimeAlert('Planilha enviada com sucesso')
            pegarListaDePlanilhas()
          }
        }

        loadingUpload.value = false
        limparArquivo()
      } catch (error) {
        console.error("Error uploading file:", error);
        showTimeAlert('Erro ao enviar planilha', 'error')
        loadingUpload.value = false
      }
    }

    async function pegarListaDePlanilhas() {
      const lista: any[] = [];
      const data = await listaBlobs(caminhoDoArquivo)
      data.sort((a, b) => b.properties.lastModified - a.properties.lastModified)

      data.forEach(i => {
        if (i) {
          const item = {
            nome: i.name.replace(caminhoDoArquivo + '/', ''),
            status: 'concluído',
            erro: false,
            detalheErro: '',
            data: formatData(i.properties.lastModified),
            pasta: i.name,
          }
          lista.push(item)
        }
      })
      historicoArquivos.value = lista
      historicoArquivos.value = lista.slice(0, 10)
      qtdArquivosSalvos.value = data.length
    }

    function formatData(data) {
      const dataFormatada = moment(data).format('DD/MM/YYYY');
      return dataFormatada;
    }

    ///////////////////// MODAL PRODUTO LOG /////////////////////

    function abreModal(arquivo?: any) {
      arquivoSelecionado.value = arquivo || {}
      modalPlanilhaLubrificanteTransmissao.value.show()
    }

    async function fechaModal() {
      arquivoSelecionado.value = {}
      modalPlanilhaLubrificanteTransmissao.value.hide()
    }

    ///////////////////// MODAL PRODUTO LOG /////////////////////

    //////////////// MODAL DOWNLOAD PLANILHA ////////////////////

    function abreModalDownload(arquivo?: any) {
      arquivoSelecionado.value = arquivo || {}
      modalDownloadPlanilhaFiltro.value.show()
    }

    function fechaModalDownload() {
      arquivoSelecionado.value = ""
      modalDownloadPlanilhaFiltro.value.hide()
    }

    //////////////// MODAL DOWNLOAD PLANILHA ////////////////////


    onMounted(async () => {
      modalPlanilhaLubrificanteTransmissao.value = new Modal(document.querySelector(`#ModalPlanilhaLubrificanteTransmissao`));
      modalDownloadPlanilhaFiltro.value = new Modal(document.querySelector(`#ModalDownloadLubrificanteTransmissao`));
      pegarListaDePlanilhas()
    })

    return {

      dropHandler,
      stopDefault,
      arquivo,
      limparArquivo,
      enviarPlanilha,
      loadingUpload,
      marcas,
      marca,
      historicoArquivos,
      openInput,
      uploadFile,
      fileInput,
      history,
      qtdArquivosSalvos,
      abreModal,
      fechaModal,
      arquivoSelecionado,
      abreModalDownload,
      fechaModalDownload,
    }
  },
});
