import { BlobServiceClient } from '@azure/storage-blob';
 
export type TOptions = {
  blobHTTPHeaders: {
    blobContentType: string;
  };
}
 
export async function uploadMediaFromClient(file: File, blobName: string, options?: TOptions) {
  const blobServiceClient = new BlobServiceClient(`https://${process.env.VUE_APP_AZURE_STORAGE_NAME}.blob.core.windows.net?${process.env.VUE_APP_AZURE_STORAGE_KEY}`);
  const containerClient = blobServiceClient.getContainerClient(process.env.VUE_APP_AZURE_STORAGE_CONTAINER!);
  const blockBlobClient = containerClient.getBlockBlobClient(blobName);
 
  return await blockBlobClient.uploadData(file, options);
}
 
export async function deleteMediaFromClient(blobName: string) {
  const blobServiceClient = new BlobServiceClient(`https://${process.env.VUE_APP_AZURE_STORAGE_NAME}.blob.core.windows.net?${process.env.VUE_APP_AZURE_STORAGE_KEY}`);
  const containerClient = blobServiceClient.getContainerClient(process.env.VUE_APP_AZURE_STORAGE_CONTAINER!);
 
  return await containerClient.deleteBlob(blobName)
}


export async function downloadXlsx(blobName){
    const blobServiceClient = new BlobServiceClient(`https://${process.env.VUE_APP_AZURE_STORAGE_NAME}.blob.core.windows.net?${process.env.VUE_APP_AZURE_STORAGE_KEY}`);
    const containerClient = blobServiceClient.getContainerClient(process.env.VUE_APP_AZURE_STORAGE_CONTAINER!);
    const blobClient = containerClient.getBlobClient(blobName);
    const tempBlockBlobClient = containerClient.getBlockBlobClient(blobName);

    const url = tempBlockBlobClient.url
    return url;
}


export async function listaBlobs(path){
    const blobServiceClient = new BlobServiceClient(`https://${process.env.VUE_APP_AZURE_STORAGE_NAME}.blob.core.windows.net?${process.env.VUE_APP_AZURE_STORAGE_KEY}`);
    const containerClient = blobServiceClient.getContainerClient(process.env.VUE_APP_AZURE_STORAGE_CONTAINER!);

    //escolha por baixar todos os blobs para poder fazer a ordenação
    // const maxPageSize = 10;
    const iterator = containerClient.listBlobsFlat({ prefix: path });

    const result = [] as any[];
    
    let blobItem = await iterator.next();
    while (!blobItem.done) {
        result.push(blobItem.value)
        blobItem = await iterator.next();
    }
    
    return result;
}
