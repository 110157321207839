

import { defineComponent, Ref, ref, watch } from "vue";
import type { PropType } from "vue";
import { downloadXlsx } from "@/services/AzureService"



export default defineComponent({
  name: "ModalPlanilhaLubrificanteTransmissao",
  props: {
    arquivo: Object as PropType<any>,
    fechaModal: Function,
    abreModal: Function,
  },

  setup(props) {

    const arquivoInfo: Ref<any> = ref({});

    function closeModal(){
      arquivoInfo.value = {};
      props.fechaModal && props.fechaModal()
    }

    function abreModal(){
      arquivoInfo.value = props.arquivo
    }



    async function baixaPlanilha() {

      const url = await downloadXlsx(arquivoInfo.value.pasta)

      console.log('url', url)

      const nomeArquivo = arquivoInfo.value.nome

      const link = document.createElement('a');
      link.href = url;
      link.download = nomeArquivo;
      link.click();
    }

    watch(() => props.arquivo, async () => { abreModal() }, { deep: true })

    return {
      arquivoInfo,
      closeModal,
      baixaPlanilha,
    };
  },
});
