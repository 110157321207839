
import { defineComponent, Ref, ref, onMounted } from "vue";
import Api from "@/services/Api";

interface VeiculoFiltroType {
  marca: string;
  modelo: string;
  fipe: string;
  anoInicial: string;
  anoFinal: string;
}

export default defineComponent({
  name: "ModalDownloadLubrificanteTransmissao",
  props: {
    fechaModal: Function,
  },

  setup(props) {

    const arquivo: Ref<any> = ref({});

    const modalDialogo: Ref<any> = ref()

    const mensagem: Ref<string> = ref('');

    const veiculoFiltro: Ref<VeiculoFiltroType> = ref({ marca: "", modelo: "", fipe: "", anoInicial: "", anoFinal: "" })

    const arrayMarcas: Ref<any[]> = ref([])

    const loadDownload = ref(false)


    function aplicaMascara() {
      veiculoFiltro.value.anoInicial = veiculoFiltro.value.anoInicial.replace(/\D/g, '').slice(0, 4);
      veiculoFiltro.value.anoFinal = veiculoFiltro.value.anoFinal.replace(/\D/g, '').slice(0, 4);
    }

    function closeModal() {
      arquivo.value = {};
      props.fechaModal && props.fechaModal()
    }

    // function abreModal() {

    //   // arquivo.value = props.arquivo

    //   console.log(arquivo.value)

    // }

    function verificaAno(): boolean {

      const { anoInicial, anoFinal } = veiculoFiltro.value

      if (anoFinal && anoInicial > anoFinal) {

        mensagem.value = '*Ano inicial não pode ser maior que o ano final';
        return true

      }

      mensagem.value = ''
      return false

    }

    async function baixar() {

      loadDownload.value = true;

      if (verificaAno()) return;

      const response = await Api.get("downloadPlanilhaLubrificanteTransmissao", { ...veiculoFiltro.value }, { responseType: 'blob' });

      baixaArquivo(response, 'lubrificante_transmissao.xlsx')

      props.fechaModal && props.fechaModal()

      loadDownload.value = false;

    }

    function baixaArquivo(blobParam: any, name: string) {

      const url = URL.createObjectURL(new Blob([blobParam]));
      const link = document.createElement('a');
      link.href = url;
      link.download = name;
      link.click();
      URL.revokeObjectURL(url);

    }

    const getVehicleBrands = async () => arrayMarcas.value = (await Api.get('getMarcasLubrificanteTransmissao')).data.marcas

    onMounted(async () => {
      getVehicleBrands()
    })

    return {
      baixar,
      arquivo,
      closeModal,
      arrayMarcas,
      modalDialogo,
      veiculoFiltro,
      verificaAno,
      aplicaMascara,
      mensagem,
      loadDownload
    };
  },
});
